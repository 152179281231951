import AptosLogoSvg from 'assets/svg/aptos-logo.svg'
import ArbitrumLogoSvg from 'assets/svg/arbitrum_logo.svg'
import AvalancheLogoSvg from 'assets/svg/avalanche-logo.svg'
import BaseLogoSvg from 'assets/svg/base-logo-in-blue.svg'
import BlastLogoSvg from 'assets/svg/blast-logo.svg'
import CantoLogoSvg from 'assets/svg/canto-logo.svg'
import EthereumLogoSvg from 'assets/svg/ethereum_square_logo.svg'
import InjectiveLogoSvg from 'assets/svg/injective-logo.svg'
import MantleLogoSvg from 'assets/svg/mantle-logo.svg'
import NearLogoSvg from 'assets/svg/near-logo.svg'
import NobleLogoSvg from 'assets/svg/noble-logo.svg'
import OptimismLogoSvg from 'assets/svg/optimism_square_logo.svg'
import PolygonLogoSvg from 'assets/svg/polygon_square_logo.svg'
import SolanaLogoSvg from 'assets/svg/solana-logo.svg'
import StellarLogoSvg from 'assets/svg/stellar-logo.svg'
import SuiLogoSvg from 'assets/svg/sui-logo.svg'

interface NetworkObjectInterface {
  name: string
  id: string
  logo: string
}

const networks: Map<string, NetworkObjectInterface> = new Map([
  ['arbitrum', { name: 'Arbitrum', id: 'arbitrum', logo: ArbitrumLogoSvg }],
  ['ethereum', { name: 'Ethereum', id: 'ethereum', logo: EthereumLogoSvg }],
  ['avalanche', { name: 'Avalanche', id: 'avalanche', logo: AvalancheLogoSvg }],
  ['injective', { name: 'Injective', id: 'injective', logo: InjectiveLogoSvg }],
  ['optimism', { name: 'Optimism', id: 'optimism', logo: OptimismLogoSvg }],
  ['polygon', { name: 'Polygon', id: 'polygon', logo: PolygonLogoSvg }],
  ['base', { name: 'Base', id: 'base', logo: BaseLogoSvg }],
  ['solana', { name: 'Solana', id: 'solana', logo: SolanaLogoSvg }],
  ['aptos', { name: 'Aptos', id: 'aptos', logo: AptosLogoSvg }],
  ['mantle', { name: 'Mantle', id: 'mantle', logo: MantleLogoSvg }],
  ['sui', { name: 'SUI', id: 'sui', logo: SuiLogoSvg }],
  ['noble', { name: 'Noble', id: 'noble', logo: NobleLogoSvg }],
  ['near', { name: 'Near', id: 'near', logo: NearLogoSvg }],
  ['canto', { name: 'Canto', id: 'canto', logo: CantoLogoSvg }],
  ['stellar', { name: 'stellar', id: 'stellar', logo: StellarLogoSvg }],
  ['blast', { name: 'Blast', id: 'blast', logo: BlastLogoSvg }],
])

// export const getSingleNetwork = (id: string) => networks.find((n) => n.id === id)

export const getNetworkArray = (ids: string[]) => {
  return ids.reduce<NetworkObjectInterface[]>((aggregatedNetworks, id) => {
    const network = networks.get(id)
    if (network) aggregatedNetworks.push(network)
    return aggregatedNetworks
  }, [])
}
