export const addSmartLookScript = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = `
    window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '8ef696b2a53b2a5ce8683d33f5847c59fd586d78');
  `
  document.head.appendChild(script)
}

export const addAppziScript = () => {
  const script = document.createElement('script')
  script.src = 'https://w.appzi.io/w.js?token=CWFYl'
  script.async = true
  document.head.appendChild(script)
}

export const addAmplitudeScript = () => {
  // const script = document.createElement('script')
  // script.src = 'https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz'
  // script.async = true
  // document.head.appendChild(script)

  // const script2 = document.createElement('script')
  // script2.src = 'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.8.0-min.js.gz'
  // script2.async = true
  // document.head.appendChild(script2)

  // const script3 = document.createElement('script')
  // script3.innerHTML = `window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}));
  // window.amplitude.init('c6e4c5fbb507fa7b07b07f33517d3031', {"autocapture":{"elementInteractions":true}});
  // `
  // document.head.appendChild(script3)

  const script = document.createElement('script')
  script.src = 'https://cdn.amplitude.com/script/c6e4c5fbb507fa7b07b07f33517d3031.js'
  script.async = true
  document.head.appendChild(script)

  const script2 = document.createElement('script')
  script2.innerHTML = `window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}));
  window.amplitude.init('c6e4c5fbb507fa7b07b07f33517d3031', {"fetchRemoteConfig":true,"autocapture":true});
  `
  document.head.appendChild(script2)
}
