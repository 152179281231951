import { Trans } from '@lingui/macro'
import { sendAnalyticsEvent, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import PortfolioDrawer, { useAccountDrawer } from 'components/AccountDrawer'
import PrefetchBalancesWrapper from 'components/AccountDrawer/PrefetchBalancesWrapper'
import Loader from 'components/Icons/LoadingSpinner'
import { IconWrapper } from 'components/Identicon/StatusIcon'
import { useGetConnection } from 'connection'
import { Portal } from 'nft/components/common/Portal'
import { useIsNftClaimAvailable } from 'nft/hooks/useIsNftClaimAvailable'
import { darken } from 'polished'
import { useCallback, useMemo, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import styled from 'styled-components/macro'
import { flexRowNoWrap } from 'theme/styles'

import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/types'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import { RowBetween } from '../Row'

// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${flexRowNoWrap};
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 2px;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.accentFailure};
  border: 1px solid ${({ theme }) => theme.accentFailure};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.accentFailure)};
  }
`

const Web3StatusConnectWrapper = styled.div<{ faded?: boolean }>`
  ${flexRowNoWrap};
  align-items: center;
  background-color: transparent;
  border-radius: ${FULL_BORDER_RADIUS}px;
  border: none;
  padding: 0;
  height: 40px;

  color: ${({ theme }) => theme.accentAction};
  :hover {
    color: ${({ theme }) => theme.accentActionSoft};
    stroke: ${({ theme }) => theme.accentActionSoft};
  }

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{
  pending?: boolean
  isClaimAvailable?: boolean
}>`
  background-color: rgba(255, 255, 255, 0.08);
  border: none !important;
  color: ${({ pending, theme }) => (pending ? theme.white : theme.textPrimary)};
  font-weight: 500;
  padding: 20px 16px;
  :hover,
  :focus {
    :focus {
    }
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: ${({ pending }) => !pending && '36px'};

    ${IconWrapper} {
      margin-right: 0;
    }
  }
`

const AddressAndChevronContainer = styled.div`
  display: flex;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.navSearchInputVisible}px`}) {
    display: none;
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

const StyledConnectButton = styled.button`
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.08);
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 16px;
  color: #fff;
`

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()
  const getConnection = useGetConnection()
  const connection = getConnection(connector)
  const [, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
    toggleAccountDrawer()
  }, [toggleAccountDrawer])
  const isClaimAvailable = useIsNftClaimAvailable((state) => state.isClaimAvailable)

  const error = useAppSelector((state) => state.connection.errorByConnectionType[getConnection(connector).type])

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  }

  const COOKIE_KEY = 'signed'
  const [showSign, setShowSign] = useState(false)
  const [cookieState, setCookieState] = useState(() => {
    const cookieValue = getCookie(COOKIE_KEY)
    return cookieValue === 'true' // Convert cookie value to boolean
  })

  // Helper function to get the value of a cookie

  // Helper function to set a cookie
  const setCookie = (name, value, days) => {
    const expires = days ? `; expires=${new Date(Date.now() + days * 86400000).toUTCString()}` : ''
    document.cookie = `${name}=${value}${expires}; path=/`
  }

  // Function to update the state and cookie
  const updateCookieState = (newValue) => {
    setCookieState(newValue)
    setCookie(COOKIE_KEY, newValue, 7) // Set cookie for 7 days
  }

  if (!chainId) {
    return null
  } else if (error) {
    return (
      <Web3StatusError onClick={handleWalletDropdownClick}>
        <NetworkIcon />
        <Text>
          <Trans>Error</Trans>
        </Text>
      </Web3StatusError>
    )
  } else if (account) {
    return (
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.MINI_PORTFOLIO_TOGGLED}
        properties={{ type: 'open' }}
      >
        <Web3StatusConnected
          data-testid="web3-status-connected"
          onClick={handleWalletDropdownClick}
          pending={hasPendingTransactions}
          isClaimAvailable={isClaimAvailable}
        >
          {!hasPendingTransactions && <StatusIcon size={24} connection={connection} showMiniIcons={false} />}
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>
                <Trans>{pending?.length} Pending</Trans>
              </Text>{' '}
              <Loader stroke="white" />
            </RowBetween>
          ) : (
            <AddressAndChevronContainer>
              <Text>{ENSName || shortenAddress(account)}</Text>
            </AddressAndChevronContainer>
          )}
        </Web3StatusConnected>
      </TraceEvent>
    )
  } else {
    return (
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
        element={InterfaceElementName.CONNECT_WALLET_BUTTON}
      >
        {!cookieState && showSign && (
          <div className="fixed w-screen h-screen left-1/2 -translate-x-1/2 top-[-46px] bg-[rgba(0,0,0,1)] backdrop-blur-lg z-[1000]">
            <div className="w-[500px] absolute top-1/2 left-0 right-0 m-auto -translate-y-1/2 rounded-[20px] border border-[rgba(255,255,255,0.30)] bg-[rgba(255,255,255,0.06)] p-[32px] space-y-[32px] text-center">
              <img src="/img/sig.png" className="absolute w-full h-full left-0 top-0 z-[-1]" alt="" />
              <img src="/img/logo2.png" className="size-[221px] block mx-auto" alt="" />
              <p className="text-[24px] text-white">Sign For Access</p>
              <div className="space-y-[10px]">
                <p className="text-white leading-relaxed text-[14px]">
                  By signing this message, you confirm that you are not accessing this app from, or are a resident of
                  the USA or any restricted country.
                </p>
                <p className="text-white leading-relaxed text-[14px]">For more information, please read our</p>
                <p>
                  <a
                    href="https://docs.newera.finance/legal/terms-of-service"
                    className="text-white font-bold text-[14px]"
                  >
                    Terms of Service
                  </a>
                </p>
              </div>
              <button
                onClick={() => updateCookieState('true')}
                className='shadow-[0px_4px_114px_0px_rgba(118,5,232,0.63)] cursor-pointer w-full h-[52px] text-white bg-[url("/img/but.png")] bg-cover rounded-[12px] text-[16px]'
              >
                Sign Message
              </button>
            </div>
          </div>
        )}
        <Web3StatusConnectWrapper
          tabIndex={0}
          faded={!account}
          onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
          onClick={() => (!cookieState ? setShowSign(true) : handleWalletDropdownClick())}
        >
          <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet">
            <Trans>Connect</Trans>
          </StyledConnectButton>
        </Web3StatusConnectWrapper>
      </TraceEvent>
    )
  }
}

export default function Web3Status() {
  return (
    <PrefetchBalancesWrapper>
      <Web3StatusInner />
      <Portal>
        <PortfolioDrawer />
      </Portal>
    </PrefetchBalancesWrapper>
  )
}
